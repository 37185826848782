import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCart32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 3.5c1.946 0 3.24.863 3.947 2.276.243.486.398 1.002.484 1.517.051.31.069.556.069.707v1h3.162c1.107 0 2.07.885 2.151 1.99l1.181 16.02a1.831 1.831 0 0 1-1.85 1.99H6.846c-1.103 0-1.928-.885-1.84-1.99l1.266-16.02C6.36 9.892 7.333 9 8.434 9h3.092L11.5 8c0-.151.018-.397.07-.707.085-.515.24-1.03.483-1.517.707-1.413 2-2.276 3.947-2.276m7.662 6.5-3.159.001a25.775 25.775 0 0 0 .07 1.569A2.494 2.494 0 0 1 22.5 14a2.5 2.5 0 1 1-2.933-2.463l-.008-.135a26.791 26.791 0 0 1-.056-1.401L12.554 10l.043 1.572a2.501 2.501 0 1 1-1.002-.04L11.553 10H8.434c-.583 0-1.119.493-1.164 1.07L6.003 27.088c-.04.521.321.912.844.912h18.296c.519 0 .892-.4.854-.917l-1.181-16.019c-.043-.58-.567-1.064-1.154-1.064M12 12.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m8 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-4-8c-1.554 0-2.51.637-3.053 1.724-.194.389-.32.81-.391 1.233a3.697 3.697 0 0 0-.056.53L12.527 9H19.5l.001-1c0-.099-.014-.29-.056-.543a4.371 4.371 0 0 0-.391-1.233C18.51 5.137 17.553 4.5 16 4.5" className="cart-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgCart32);
export default Memo;