import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgUser32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="m10.097 18.395.31.26.143.106.206.143c.336.227.725.454 1.164.666 3.007 1.457 6.371 1.457 9.833-.979l.265-.187.279.167c.054.032.149.092.279.18l.164.112a15.448 15.448 0 0 1 2.655 2.39C27.332 23.458 28.5 26.048 28.5 29h-1c0-2.684-1.067-5.052-2.855-7.086a14.455 14.455 0 0 0-2.12-1.971l-.257-.19-.218-.153c-3.692 2.473-7.336 2.434-10.565.87a10.489 10.489 0 0 1-1.287-.737l-.194-.134a10.852 10.852 0 0 0-3.747 3.692 12.204 12.204 0 0 0-1.538 3.943c-.096.47-.158.907-.192 1.297l-.018.242-.009.227h-1l.001-.074.009-.205.02-.278c.038-.426.105-.9.21-1.409a13.194 13.194 0 0 1 1.665-4.266 11.881 11.881 0 0 1 4.392-4.204zM16 5a7 7 0 1 1 0 14 7 7 0 0 1 0-14m0 1a6 6 0 1 0 0 12 6 6 0 0 0 0-12" className="user-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgUser32);
export default Memo;