import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M27.378 8.483c-1.885-4.117-7.239-4.796-10.62-.866l-.758.88-.758-.88c-3.381-3.93-8.735-3.251-10.62.866C2.372 13.393 5.872 20.608 16 27.78c10.128-7.172 13.627-14.386 11.378-19.297m-12.136-2.17h.145c.209.202.414.42.613.651.2-.231.404-.449.613-.652h.145l-.066-.076C24.952-1.6 40.021 12.398 16 29-8.02 12.398 7.047-1.6 15.308 6.236z" className="wishlist-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;