import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default/types';
import defaultConfig, { getFullLocaleFromConfig, environment, customArrayMerge } from '../default';

const tenantId = 'baurde';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    host: { name: 'BAUR Versand', domain: 'baur.de' },
    clientId: 'BaurDe',
    i18n: {
      defaultLocale: 'de-DE',
      locales: ['de-DE'],
      languages: ['de'],
    },
    tenantId,
    company: {
      name: 'Baur',
      seoTitleName: 'BAUR',
    },
    header: {
      hasSmallerFontSize: true,
    },
    search: {
      personalizationSegmentSource: 'baur',
      adServer: {
        mode: 'doubleClick',
        adServerClientId: '105091519',
        adServerCustomerName: 'BaurDe',
      },
      isInspiringSearchEnabled: true,
    },
    navigation: {
      firstLevel: {
        border: 'grey.main',
      },
      secondLevel: {
        color: 'text.light',
        backgroundColor: '#4A4543',
        border: 'grey.dark',
      },
      loadSliced: true,
    },
    dynamicYieldEnabled: true,
    enableCustomerNrLogin: true,
    headerSlots: {
      payback: true,
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    product: {
      cliplister: {
        customerId: '80936',
      },
      mazing: {
        enabled: false,
      },
      hasSwogo: false,
      hasCarbonHint: false,
    },
    installmentCalculator: {
      hasLegalHints: true,
      hasInfoCallToAction: false,
      InstallmentCalculatorCallToActionLayout: { layout: 'Layout1' },
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
    },
    order: {
      premium: {
        enabled: true,
      },
      seals: {
        trustedShops: {
          enabled: true,
          url: 'https://www.trustedshops.de/bewertung/info_X1661D6DE044EDCDBB810489687D380A7.html',
        },
        ehi: {
          enabled: true,
          url: 'https://zertifikat.ehi-siegel.de/de/zertifikat/s/9e3adf842e1b5f22490345c8c1752fc0/',
        },
      },
      assets: {
        premium: {
          getPremiumIcon: () => 'https://bilder.baur.de/empiriecom/io/baurde/de/premiumicon.svg',
        },
      },
      payment: {
        americanExpress: true,
      },
      payback: {
        enabled: true,
        links: {
          partner: 'https://www.payback.de/anmelden?excid=76_19489_001',
          customerData: 'https://www.payback.de/info/mein-payback/stammdaten',
        },
        cardNumberLength: 10,
        cardNumberHintUrl: '/fragment/inspire/servicelayer/de/payback_kartennummer_io',
      },
      hasCo2Compensation: false,
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'haeufige-fragen-faq'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'kontakt'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'bestellen-beraten'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'bezahlen'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'lieferung-montage'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'mitnahme-verwertung'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'ruecksendung'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'garantie-reparatur'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'shopping-vorteile'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'newsletter', 'anmeldung'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'nachhaltigkeit'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe', 'ueber-uns'] }, locale: 'de' },
        ],
      },
      cmsFontToTheme: {
        '.GothamSSm4r, .MontserratSemibold, .font-normal-600': {
          fontWeight: 'fontWeightSemiBold',
        },
        '.GothamSSm5r, .MontserratBold, .font-normal-700': {
          fontWeight: 'fontWeightBold',
        },
        '.GothamSSm7r, .MontserratExtraBold, .font-normal-800': {
          fontWeight: 'fontWeightBlack',
        },
        '.GothamNarrow, .MontserratItalic, .font-italic-400': {
          fontWeight: 'fontWeightLight',
          fontStyle: 'italic',
        },
      },
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { type: ['full'] }, locale: 'de' },
          { params: { type: ['socket'] }, locale: 'de' },
        ],
      },
    },
    forms: {
      contact: {
        selectOptionsSubject: [
          { label: 'forms.type.subject.option.order', value: 'order' },
          { label: 'forms.type.subject.option.datasecurity', value: 'datasecurity' },
          { label: 'forms.type.subject.option.web', value: 'web' },
          { label: 'forms.type.subject.option.account', value: 'account' },
          { label: 'forms.type.subject.option.personalData', value: 'personalData' },
          { label: 'forms.type.subject.option.praemie', value: 'praemie' },
          { label: 'forms.type.subject.option.product', value: 'product' },
          {
            label: 'forms.type.subject.option.complaint.textileshoes',
            value: 'complaint_textile-shoes',
          },
          {
            label: 'forms.type.subject.option.complaint.hometextile',
            value: 'complaint_hometextile',
          },
          {
            label: 'forms.type.subject.option.complaint.furtherassortments',
            value: 'complaint',
          },
          { label: 'forms.type.subject.option.complaint.returnsprocessing', value: 'return' },
        ],
      },
      newsletterUnsubscribe: {
        fields: [
          {
            id: 'newsletter',
            formType: 'radio',
            options: [
              {
                id: 'frq-daily',
                value: 'frequency|0',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.daily',
              },
              {
                id: 'frq-weekly',
                value: 'frequency|1',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.weekly',
              },
              {
                id: 'frq-bi-weekly',
                value: 'frequency|2',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.biweekly',
              },
              {
                id: 'frq-monthly',
                value: 'frequency|3',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.monthly',
              },
              {
                id: 'unsubscribe',
                value: 'unsubscribe|99',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe',
              },
              {
                id: 'pause',
                value: 'pause|-100',
                messageKey: 'forms.newsletter.unsubscribe.type.pause',
              },
            ],
            additionalForm: {
              id: 'pause',
              formType: 'select',
              showOnlyWhenWhatSelected: 'pause',
              messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
              options: [
                {
                  id: 'pause-7',
                  value: '7',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
                },
                {
                  id: 'pause-14',
                  value: '14',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
                },
                {
                  id: 'pause-21',
                  value: '21',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
                },
                {
                  id: 'pause-30',
                  value: '30',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
                },
              ],
            },
          },
        ],
      },
      newsletterSubscribe: {
        de: {
          unsubscribeLink: 'https://www.baur.de/service-hilfe/newsletter/abmelden',
        },
      },
      validation: {
        rules: {
          customerId: {
            regex: /^[A-Za-z0-9.\-ßÄÖÜäöü ]*$/,
            errorMessage: {
              id: 'forms.error.no.special.characters',
              defaultMessage: 'Sonderzeichen sind nicht erlaubt',
            },
          },
        },
      },
    },
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    sovendus: {
      enabled: false,
    },
    chatBot: {
      enabled: true,
      key: '418f4be1c3574c2daa41d05ac9603d32c52d03af187b49dc81805ff5eb0a2c33',
      includePaths: ['/service-hilfe'],
      excludePaths: [
        '/service-hilfe/shopping-vorteile',
        '/service-hilfe/newsletter/anmeldung',
        '/service-hilfe/newsletter/abmeldung',
        '/service-hilfe/nachhaltigkeit',
        '/service-hilfe/ueber-uns',
      ],
    },
    promotionBanner: {
      apiEndpoints: {
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
      },
      abTest: '2023_11_Hebel2',
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    appBanner: {
      appUrl:
        'https://sdnq.adj.st/storefront?adj_t=55kqkm_8v5x1k&adj_deep_link=baurde%3A%2F%2Fstorefront&adj_fallback=https%3A%2F%2Fwww.baur.de%2Flp%2Fapp',
    },
    tracking: {
      soastaId: 'FP553-5JWM6-76MK6-QR4XN-3XLRP',
      tagmanagerId: 'GTM-W3MMXTW',
      tagmanagerSrc: 'https://s.baur.de/btm',
      googleSiteVerificationID: ['ATfbFtTz8sjSDlqWCr13kE9BobcWVlJbM2LlVubNlzs'],
      msvalidateID: 'A5FDCE2E03DF648A7B16A2F9CDE6C0DC',
      pinterestRichPinVerificationID: 'a75fa11cb37d4c1eb5e5e3ab4d870d52',
      schemaOrgOrganisation: {
        'de-DE': {
          addressCountry: 'DE',
          addressRegion: 'BY',
          addressLocality: 'Burgkunstadt',
          alternateName: ['BAUER', 'BAUER Versand'],
          areaServed: 'DE',
          contactEmail: 'service@baur.de',
          contactUrl: 'https://www.baur.de/service-hilfe/kontakt',
          description:
            'We-Commerce. Seit 1925. BAUR vereint Teamgeist und Handel: Wir zählen zu den Pionieren im Versandgeschäft und gehen mit der Zeit – vom bewährten Katalog- zum modernen Online-Händler. In unserem Online-Shop bieten wir vor allem Mode, Schuhe und Möbel an­ – wir achten auf Qualität, Service und ein aktuelles Angebot.',
          email: 'service@baur.de',
          hoursAvailable: ['Mo-Su 6:00-23:00'],
          legalName: 'BAUR Versand GmbH & Co. KG',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'BAUR Versand',
          postalCode: '96224',
          privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
          productSupported: '',
          sameAs: [
            'https://www.facebook.com/BAUR',
            'https://www.instagram.com/baur.de/',
            'https://de.pinterest.com/baurversand/',
            'https://www.youtube.com/user/baurversand',
            'https://www.xing.com/pages/baurversand',
            'https://de.linkedin.com/company/baur-versand',
            'https://de.wikipedia.org/wiki/Baur_Versand',
          ],
          streetAddress: 'Bahnhofstraße 10',
          telephone: '+49-9572-5050',
          url: 'https://www.baur.de',
          potentialAction: {
            target: 'https://www.baur.de/s/',
          },
        },
      },
    },
    headerInformation: {
      de: {
        description:
          'lll➤ zum Online-Shop lll➤ Über 4.000 Marken ✅ Kauf auf Rechnung & Raten ▷ PAYBACK Punkte sammeln ✅ Neukunden-Rabatt + Gratis Versand',
        title: 'BAUR Online Shop ▷ Mode | Wohnen | Technik & mehr',
        canonical: 'https://www.baur.de',
        robots: 'noodp,index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      tenantId: '1900',
    },
    dynamicYieldTracking: {
      key: 'd21ba946a03714ea5a981986bd6d2e00a6ad17f63c71e66f9b7f046cbab50aab',
    },
    geoCountryLayer: {
      enabled: true,
      flag: 'de',
      alternatives: [
        {
          flag: 'de',
          shops: [{ closeLayer: true }],
        },
      ],
    },
    lounge: {
      icon: (lang: string) => `https://bilder.baur.de/empiriecom/io/baurde/${lang}/premiumicon.svg`,
    },
    bookings: {
      overview: {
        hasExplicitMaturities: true,
        showOpenPayment: 'installments',
      },
    },
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
