import deepmerge from '@mui/utils/deepmerge';
import { createTheme } from '@mui/material/styles';
import type { Theme, ThemeOptions } from '@mui/material/styles';
import { DEFAULTCOLOR_TEXT_DISABLED } from '../default/palette';
import { generateDefaultThemeOptions } from '../default';
import type { Device } from '../default';
import { fonts } from './fonts';

const COLOR_PRIMARY_MAIN = '#fdc300';
const DEFAULTCOLOR_TEXT_DARK = '#000000';
const DEFAULTCOLOR_TEXT_TRANSPARENT_DARK = 'rgba(0, 0, 0, 0.65)';
const DEFAULTCOLOR_COMMON_LIGHT = '#f4f4f4';

/** Define theme object.
 * See here for more info: https://mui.com/customization/theming/ */
const themeOptions: ThemeOptions = {
  // https://mui.com/customization/palette/
  palette: {
    primary: {
      main: COLOR_PRIMARY_MAIN,
      textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
    },
    secondary: {
      main: '#ef7c00',
    },
    grey: {
      main: '#d5d3d1',
      light: DEFAULTCOLOR_COMMON_LIGHT,
      dark: '#8c8480',
    },
    text: {
      disabled: DEFAULTCOLOR_TEXT_DISABLED,
      primary: DEFAULTCOLOR_TEXT_DARK,
      secondary: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
      dark: DEFAULTCOLOR_TEXT_DARK,
      darkTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
    },
    footer: {
      main: DEFAULTCOLOR_COMMON_LIGHT,
      contrastText: DEFAULTCOLOR_TEXT_DARK,
    },
  },
  // https://mui.com/customization/typography/
  typography: {
    fontFamily: fonts.primary.style.fontFamily,
    fontWeightRegular: 400,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightBlack: 800,
  },
  // https://mui.com/customization/theme-components/
  components: {
    MuiCssBaseline: {
      styleOverrides: '',
    },
    MuiButton: {
      styleOverrides: {
        root: ({
          theme: {
            unstable_sx: sx,
            palette: { grey },
          },
        }) =>
          sx({
            ':disabled': { bgcolor: grey.main, color: grey.dark },
          }),
        containedPrimary: ({
          theme: {
            unstable_sx: sx,
            palette: { text, grey },
          },
        }) =>
          sx({
            color: text.dark,
            background: `linear-gradient(${COLOR_PRIMARY_MAIN}, #fdb300 80%)`,
            ':disabled': { background: 'none', bgcolor: grey.main },
            ':hover': {
              background: 'linear-gradient(#fdb400, #fda500 80%)',
            },
          }),
      },
    },
  },
};

/** Merge with default theme options and create a theme instance
 * See here for more info: https://mui.com/customization/theming/#api */
const themes: { [type in Device]: Theme | undefined } = {
  mobile: undefined,
  tablet: undefined,
  desktop: undefined,
};

const theme = (device: Device = 'desktop') => {
  if (!themes[device]) {
    themes[device] = createTheme(deepmerge(generateDefaultThemeOptions(device), themeOptions));
  }

  return themes[device];
};

// eslint-disable-next-line import/no-default-export
export default theme;
