import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgService32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 27c6.075 0 11-4.925 11-11S22.075 5 16 5 5 9.925 5 16s4.925 11 11 11m0-23c6.627 0 12 5.373 12 12s-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4m0 9.5-.117.007a.997.997 0 0 0-.883.992V22c0 .554.448.999 1 .999l.117-.007c.5-.057.883-.48.883-.992V14.5a.998.998 0 0 0-1-.999M16 9a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" className="service-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgService32);
export default Memo;