import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlistCss32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" data-name="Ebene 1" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#fff" d="M16 7c-8.1-9.4-24.7 4.9 0 22 24.7-17.1 8.1-31.4 0-22" /><path d="M22 4a7.65 7.65 0 0 0-6 3 7.94 7.94 0 0 0-6-3C2.6 4-3.3 15.7 16 29 35.3 15.7 29.4 4 22 4m0 1a5.88 5.88 0 0 1 5.6 4.2c.6 2 1.6 9.1-11.7 18.5C2.7 18.4 3.7 11.2 4.3 9.2A5.91 5.91 0 0 1 10 5a7.13 7.13 0 0 1 5.3 2.6l.7.9.8-.9A7.11 7.11 0 0 1 22 5" /></SvgIcon>;
};
const Memo = memo(SvgWishlistCss32);
export default Memo;