import localFont from 'next/font/local';

const primary = localFont({
  src: [
    {
      path: './fonts/montserrat-regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/montserrat-italic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: './fonts/montserrat-semibold.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: './fonts/montserrat-bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/montserrat-extrabold.woff2',
      weight: '800',
      style: 'normal',
    },
  ],
  variable: '--font-primary',
});

const payback = localFont({
  src: [
    {
      path: './fonts/payback-bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/payback-regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/payback-light.woff2',
      weight: '300',
      style: 'normal',
    },
  ],
  preload: false,
  variable: '--font-payback',
});
export const fonts = {
  primary,
  payback,
};
