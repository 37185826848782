import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCheckmark32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M7.734 15.987a.49.49 0 0 0-.702-.004L5.144 17.89a.503.503 0 0 0 .003.707l6.193 6.254a.515.515 0 0 0 .72.004L26.84 10.76a.49.49 0 0 0 .02-.703L24.972 8.15a.512.512 0 0 0-.709-.014L11.692 19.984z" className="checkmark-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgCheckmark32);
export default Memo;